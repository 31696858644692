<template>
  <aCollapse
    class="travel-filters expandable mt-0 mb-10"
    activeKey="1"
    expandIconPosition="right"
  >
    <aCollapsePanel key="1">
      <template #header>
        <aIcon type="filter" class="mr-5" /> FILTRAR
      </template>

      <aRow class="fiter-collumns" :gutter="20">
        <aCol :span="6">
          <div class="travel-input">
            <label class="filled">Fornecedores</label>
            <aSelect
              class="travel-input"
              placeholder="Selecione o fornecedor"
              v-model="filters.productSupplier"
              optionFilterProp="txt"
              show-search
              allow-clear
              style="width: 100%"
              @change="onSelectProductSupplier"
            >
              <a-select-option
                v-for="(item, index) of productSupplier.list"
                :key="index"
                :value="item.trading_name"
                :txt="item.trading_name"
              >
                {{ item.id }} -
                {{ item.trading_name.toUpperCase() }}
              </a-select-option>
            </aSelect>
          </div>
        </aCol>

        <aCol :span="3">
          <div class="travel-input">
            <label class="filled">ID do contrato</label>
            <a-input
              placeholder="ID + Enter"
              v-model="filters.id"
              @pressEnter="onClickFilterContracts"
            />
          </div>
        </aCol>

        <aCol :span="3">
          <div class="travel-input">
            <label class="filled">Empresas</label>
            <a-select
              class="travel-input"
              placeholder="Selecione"
              v-model="filters.companies.selected"
              show-search
              allow-clear
              style="width: 100%"
              @change="onSelectCompany"
            >
              <a-select-option
                v-for="(item, index) of companies.list"
                :key="index"
                :value="item.id"
              >
                {{ item.trading_name }}
              </a-select-option>
            </a-select>
          </div>
        </aCol>

        <aCol :span="12">
          <div class="travel-input">
            <label class="filled">Filiais</label>
            <a-select
              class="travel-input"
              placeholder="Selecione"
              mode="multiple"
              v-model="filters.companyBranches.selected"
              show-search
              style="width: 100%"
            >
              <a-select-option
                v-for="(item, index) of companyBranches.list"
                :key="index"
                :value="item.id"
              >
                {{ item.id }} - {{ item.name }}
              </a-select-option>
            </a-select>
          </div>
        </aCol>

        <aCol :span="6">
          <div class="travel-input">
            <label for class="filled">Período</label>
            <a-range-picker
              v-model="filters.period.selected"
              :format="['DD/MM/YYYY']"
              :value-format="'YYYY-MM-DD'"
              @change="getContracts()"
            />
          </div>
        </aCol>

        <aCol v-if="activeTab !== 'Na fila'" :span="8">
          <div class="field payments">
            <span class="label">Status</span>
            <a-checkbox-group
              style="width: 100%"
              v-model="filters.invoiceStatus.selected"
            >
              <a-checkbox
                v-for="(item, index) of [
                  { label: 'Pago', value: 'Pago' },
                  { label: 'Pendente', value: 'Pendente' },
                  { label: 'Em aberto', value: 'Em aberto' },
                  { label: 'Recebido', value: 'Recebido' },
                ]"
                :key="index"
                :value="item.value"
              >
                {{ item.label }}
              </a-checkbox>
            </a-checkbox-group>
          </div></aCol
        >
      </aRow>
      <a-divider class="mt-0 mb-15" />
      <a-button
        class="mb-0"
        type="primary"
        :disabled="filters.productSupplier ? false : true"
        :loading="loading"
        @click="onClickFilterContracts()"
      >
        <a-icon type="search" /> Filtrar
      </a-button>
    </aCollapsePanel>
  </aCollapse>
</template>

<script>
import { format } from "date-fns";

import companyMixins from "@/mixins/companies/companyMixins";
import companyBranchMixins from "@/mixins/company-branches/companyBranchMixins";
import productSupplierMixins from "@/mixins/product-suppliers/productSupplierMixins.js";

export default {
  name: "BillsToPayFiltersCollapse",
  props: {
    filters: Object,
    loading: Boolean,
    activeTab: String,
  },
  mixins: [companyMixins, companyBranchMixins, productSupplierMixins],
  data() {
    return {};
  },
  mounted() {
    this.getCompanyBranches();
    this.getCompanies();
    this.productSupplier.pagination.perPage = 300;
    this.productSupplier.filters.only_specific_categories = 1;
    this.getProductSuppliers();

    let date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth(),
      firstDay = new Date(y, m, 1),
      lastDay = new Date(y, m + 1, 0);
    firstDay = format(firstDay, "yyyy-MM-dd");
    lastDay = format(lastDay, "yyyy-MM-dd");

    this.filters.status.selected = "concluded";
    this.filters.invoiceStatus.selected = ["Em aberto"];
    this.filters.joinSales = "yes";
    this.filters.orderBy = "created";
    this.filters.order = "desc";
    this.filters.users.selected = [];
    this.filters.period.selected = [`${firstDay}`, `${lastDay}`];
    this.filters.productsCategory.selected = ["flight"];
    this.filters.flightType.selected = ["Regular"];
  },
  methods: {
    onClickFilterContracts() {
      this.$emit("onClickFilterContracts", this.filters);

      this.filters.productSupplier = Array.isArray(this.filters.productSupplier)
        ? this.filters.productSupplier[0]
        : this.filters.productSupplier;

      this.onSelectProductSupplier(this.filters.productSupplier);
    },
    onSelectProductSupplier(selected) {
      const selectedProductSupplier = this.productSupplier.list.filter(
        (productSupplier) => {
          return productSupplier.trading_name === selected;
        }
      );

      if (selectedProductSupplier.length) {
        this.$emit("onSelectProductSupplier", selectedProductSupplier[0]);
      } else {
        this.$emit("onSelectProductSupplier", {});
      }

      this.$emit("onClickFilterContracts", this.filters);
    },
    onSelectCompany(val) {
      this.companyBranches.filters.companyId = val;
      this.companyBranches.list = [];
      this.getCompanyBranches();
    },
  },
};
</script>
